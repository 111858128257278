<template>
    <v-card>
        <v-card-title>
            <div class="card-header text-unset">{{tableTitle}}</div>
            <v-spacer></v-spacer>
            <v-text-field
                v-model="search"
                append-icon="search"
                label="Search"
                single-line
                hide-details
            ></v-text-field>
        </v-card-title>
        <v-data-table v-if="vouchers" dense :headers="headers" :items="vouchers" item-key="id" :items-per-page="10" sort-by="used" :pagination.sync="pagination" :search="search" :rows-per-page-items="itemsPerPage">
            <template v-slot:items="props">
                <td class="text-right w-5">
                    <font-awesome-icon style="font-size: 1.4em" :icon="['fas', 'tag']" class="text-primary" v-bind:class="{'text-danger':props.item.used}"></font-awesome-icon>
                </td>
                <td>
                    <small><b>{{ props.item.id }}</b></small>
                </td>
                <td>
                    {{ props.item.name }}
                </td>
                <td class="date-time-width">
                    {{ props.item.gotDate|formatDateShort }}
                </td>
                <td class="date-time-width">
                    {{ props.item.usedDate|formatDateShort }}
                </td>
                <td style="width: 100px">
                    {{ props.item.value }}
                </td>

                <td class="text-center">
                    <span class="badge" :class="props.item.used ? pendingOrUsed(props.item) ? 'badge-warning' : 'badge-danger' : 'badge-primary'">
                        {{ props.item.used ? pendingOrUsed(props.item) ? "PENDING" : "USED" : "AVAILABLE" }}
                    </span>
                </td>

                <td>
                    <span v-if="props.item.user">{{ props.item.user }}</span>
                </td>
                <td>
                    <v-btn color="error" style="font-size: 1em" v-on:click="deleteVoucher(props.item)">Delete</v-btn>
                </td>

            </template>
        </v-data-table>
    </v-card>
</template>

<script>
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import { library } from '@fortawesome/fontawesome-svg-core'
import { faTag } from '@fortawesome/free-solid-svg-icons'
import * as moment from "moment";

library.add(faTag);
export default {
    name: "VouchersTable",
    components: {
            FontAwesomeIcon
    },
    data() {
        return {
            itemsPerPage: [10, 20, 50, 100, {"text":"All vouchers","value":-1}],
            pagination: {
                descending: false,
                sortBy: "used",
            },
            search: "",
            headers: [
                {value: "icon", text:"", sortable: false},
                {value:"id", text:"ID", sortable: true},
                {value: "name", text: "Voucher", sortable: true},
                {
                    value: "gotDate",
                    text: "Datum issued",
                    sortable: true,
                },
                {
                    value: "usedDate",
                    text: "Date used",
                    sortable: true,
                },
                {value: "value", text: "Value", sortable: true},
                {value: "used", text: "Used", sortable: true, class: "text-center"},
                {value: "user", text: "User", sortable: true},
                {value: "delete", text: "", sortable: false}
            ],
            tableTitle: "All vouchers",
            vouchers: null,
        };
    },
    props: [
        'users',
    ],
    created() {
        this.fetchVouchers();
    },
    methods: {
        fetchVouchers() {
            this.axios.post(this.baseURL+'/admin-api/v0/bc/vsi_vavcerji?access_token=' + ((sessionStorage.getItem('apollo-token')) ? sessionStorage.getItem('apollo-token') : localStorage.getItem('apollo-token'))).then((response) => {

                this.axios.post(this.baseURL+'/admin-api/v0/bc/vse_rezervacije?access_token=' + ((sessionStorage.getItem('apollo-token')) ? sessionStorage.getItem('apollo-token') : localStorage.getItem('apollo-token'))).then((r) => {
                    this.vouchers = response.data.map(v => {
                        var reservation = r.data.find(rez => rez.vavcer == v._id)
                        return {
                            id: v._id,
                            gotDate: v.datumIzdaje,
                            usedDate: v.datumPorabe,
                            name: v.ime,
                            used: v.porabljen,
                            resDate: reservation ? reservation.zacetekRezervacije : null,
                            value: v.vrednost,
                            user: (this.users) ? this.users.find(u => u._id == v.uid).username : null
                        }

                    })

                })

            })

        },
        pendingOrUsed(i) {
            return (moment(i.resDate).diff(moment(new Date), 'days') > 14)
        },
        deleteVoucher(val) {
            this.axios.post(this.baseURL+'/admin-api/v0/bc/brisi_vavcer?access_token=' + ((sessionStorage.getItem('apollo-token')) ? sessionStorage.getItem('apollo-token') : localStorage.getItem('apollo-token')), {
                vavcer: val.id
            }).then(() => {
                this.fetchVouchers()
            })
        }
    },
    computed: {

    },
    watch: {
        users: function() {
            if(this.vouchers)
                this.vouchers = this.vouchers.map(v => {
                    return {
                        ...v,
                        user: this.users.find(u => u._id == v.uid)
                    }
                })
        }
    }
}
</script>

<style scoped>
.badge-dot-text {
    text-indent: 0em !important;
    width: 16px!important;
    height: 16px!important;
    color: white!important;
    border-radius: 50px!important;
    font-size: 0.5rem;
    padding: 2px 0 2px 0;
}

.badge-btn {
    min-width: 110px;
    border-radius: 50px!important;
}

.date-time-width {
    min-width: 150px;
}

.value-min-width {
    min-width: 150px;
}
</style>