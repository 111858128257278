<template>
    <v-card class="col-6 mt-5 d-flex flex-column" v-if="users">
        <v-card-title>
            <h3 class="h5 mb-0"><b>Add voucher</b></h3>
        </v-card-title>
        <v-container class="pt-0">
            <form>
                <v-text-field 
                label="Voucher value" 
                type="number" 
                v-model="value" 
                :mask="mask"
                required></v-text-field>
                <v-autocomplete
                item-text='username'
                v-model="user"
                :items="users"
                label="Select user"
                box
                required
                >
                    <template v-slot:item="user">
                        <v-list-tile-content>
                            <span><b>Username:</b> &nbsp; {{ user.item.username }}</span>
                            <span class="small"><b>Full name:</b> &nbsp; {{ user.item.firstName }} {{ user.item.lastName }}</span>
                        </v-list-tile-content>
                    </template>
                </v-autocomplete>
                <v-btn
                @click="addVoucher" class="btn-primary">
                Add voucher
                </v-btn>
            </form>
        </v-container>
    
    </v-card>
</template>

<script>

export default {
    name: "VouchersAdd",
    components: {
    },
    data() {
        return {
            user: null,
            value: null,
            mask: '####################'
        };
    },
    props: [
        'users',
    ],
    created() {
    },
    methods: {
        addVoucher() {
            if(this.user == null || this.value == null)
                alert("Please enter voucher value and select user")
            

            this.axios.post(this.baseURL+"/admin-api/v0/bc/dodeli_vavcer?access_token="  + ((sessionStorage.getItem('apollo-token')) ? sessionStorage.getItem('apollo-token') : localStorage.getItem('apollo-token')), {
                uporabnik: this.user,
                vrednost: parseInt(this.value)
            }).then(res => {
                if(res.data.success)
                    alert("Voucher created successfully")
            })
        }
    },
    computed: {
    },
}
</script>

<style>
.v-autocomplete__content .v-list__tile{
  height: auto;
}
</style>