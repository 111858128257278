<template>
    <div>
        <h2>Vouchers administration page</h2>
        <div class="row mb-3 d-flex justify-content-center">
            <vouchers-add :users="users"></vouchers-add>    
        </div>
        <div class="row">
            <vouchers-table :users="users" style="width: 100%"></vouchers-table>
        </div>
    </div>
</template>

<script>
import VouchersTable from '../components/Vouchers/VouchersTable'
import VouchersAdd from '../components/Vouchers/VouchersAdd'
export default {
    name: "AdminVouchersPage",
    components: {
        VouchersTable,
        VouchersAdd
    },
    data() {
        return {
            users: null
        }
    },
    methods: {    

    },
    computed: {
    },
    created() {
        this.axios.get(this.baseURL+'/admin-api/v0/mem/users?access_token=' + localStorage.getItem('apollo-token')).then(res => {
            this.users = res.data
        });
    }
}
</script>
